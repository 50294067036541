/**
 * ****************************************************
 *  CAN BE USED FOR CLIENT AND SERVER-SIDE FORMATTING
 * ****************************************************
 */

import moment from 'moment-timezone';

export function formatDate(date: Date | moment.Moment, format: string): string {
  const normalizedFormat = format.replace('z', `[${getTimeZoneLabel()}]`);
  return moment(date).tz(Intl.DateTimeFormat().resolvedOptions().timeZone).format(normalizedFormat);
}

export function getTimeZoneLabel(): string {
  const timeZoneAbbr = moment.tz(moment.tz.guess()).zoneAbbr();
  const timezone =
    timeZoneAbbr.startsWith('+') || timeZoneAbbr.startsWith('-')
      ? `UTC${timeZoneAbbr}`
      : timeZoneAbbr;
  return timezone;
}

export function buildDateStringWithTimeFromStartDateAndStartTime(
  date: string,
  time: string
): string {
  const startDate = date?.split('T')[0];
  const startTime = time?.split('T')[1];

  if (!startDate || !startTime) return date;
  return `${startDate}T${startTime}Z`;
}

export const timeDurationString = (durationInMinutes: number) => {
  const hours = Math.floor((durationInMinutes ?? 0) / 60);
  const minutes = (durationInMinutes ?? 0) % 60;

  const hoursString = hours > 1 ? `hours` : `hour`;
  const minutesString = minutes > 1 ? `minutes` : `minute`;

  if (hours > 0 && minutes > 0) {
    return `${hours} ${hoursString} and ${minutes} ${minutesString}`;
  } else if (hours > 0) {
    return `${hours} ${hoursString}`;
  } else if (minutes > 0) {
    return `${minutes} ${minutesString}`;
  } else {
    return ``;
  }
};
