import React, {
  EventHandler,
  FC,
  MouseEvent,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react';
import {
  Button,
  Center,
  CircularProgress,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr
} from '@chakra-ui/react';
import {useDisclosure} from '@chakra-ui/hooks';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSort} from '@fortawesome/pro-solid-svg-icons/faSort';
import {faSortDown, faSortUp, faArrowLeft} from '@fortawesome/pro-solid-svg-icons';
import {Scalars, useMyTeamMembersLazyQuery} from 'generated/graphql';
import _ from 'lodash';
import TeamMemberAvatar from 'components/TeamMemberAvatar';
import {useSession} from 'context/SessionContext';

interface Props {
  isOpen: boolean;
  onClose?: () => void;
  onlyActiveMembers?: boolean;
  labels?: {
    reviewTitle?: string;
    listTitle?: string;
    subtitle?: string;
  };
  isActionRunning?: boolean;
  onCheckReviewMode?: (userId: string) => boolean;
  onCheckListMode?: (userId: string) => boolean;
  onActionRun?: (userIds: string[]) => void;
  actionLabel?: (userIds: string[]) => string;
  selectedMembers?: Scalars['uuid'][];
}

const MyTeamSelectionModal: FC<Props> = (props) => {
  const {isOpen, onOpen: openModal, onClose: closeModal} = useDisclosure();

  const {data: session} = useSession();

  const [mode, setMode] = useState<'review' | 'list'>('list');
  const [sortBy, setSortBy] = useState<
    'NAME_ASC' | 'NAME_DESC' | 'TEAM_ASC' | 'TEAM_DESC' | undefined
  >('NAME_ASC');
  const [selectedMembers, setSelectedMembers] = useState<string[]>([]);

  const [fetchMyTeam, {loading: featchingTeam, data: myTeam}] = useMyTeamMembersLazyQuery({
    fetchPolicy: 'no-cache'
  });

  useEffect(() => {
    if (session?.user?.id && session.user.abilities?.editTeamEnrollments) {
      fetchMyTeam({variables: {onlyActive: props.onlyActiveMembers ?? false}});
    }
  }, [fetchMyTeam, session, props.onlyActiveMembers]);

  const members = useMemo(() => {
    return myTeam?.MyTeamMembers?.members ?? [];
  }, [myTeam]);

  const handleCloseModal = useCallback(() => {
    props.onClose?.();
    closeModal();
  }, [closeModal, props]);

  const listTitle = useMemo(
    () => props.labels?.listTitle ?? 'My Team Members',
    [props.labels?.listTitle]
  );
  const reviewTitle = useMemo(
    () => props.labels?.listTitle ?? 'My Team Members',
    [props.labels?.listTitle]
  );

  const sortedMembers = useMemo(() => {
    const membersRich = _(members ?? []);
    let subs = members ?? [];

    if (sortBy === 'NAME_ASC') subs = membersRich.orderBy(['fullName'], ['asc', 'asc']).value();
    if (sortBy === 'NAME_DESC') subs = membersRich.orderBy(['fullName'], ['desc', 'desc']).value();
    if (sortBy === 'TEAM_ASC') subs = membersRich.orderBy(['teamNames'], ['asc']).value();
    if (sortBy === 'TEAM_DESC') subs = membersRich.orderBy(['teamNames'], ['desc']).value();

    const checkFunc = mode === 'review' ? props.onCheckReviewMode : props.onCheckListMode;

    return subs.filter((s) => checkFunc?.(s.id) ?? true);
  }, [members, sortBy, mode, props.onCheckReviewMode, props.onCheckListMode]);

  const toggleAll = useCallback(() => {
    if (selectedMembers.length === sortedMembers.length) {
      setSelectedMembers([]);
    } else {
      setSelectedMembers(sortedMembers.map((s) => s.id));
    }
  }, [sortedMembers, selectedMembers.length]);

  const toggleSortName = useCallback(() => {
    if (sortBy === 'NAME_ASC') setSortBy('NAME_DESC');
    else setSortBy('NAME_ASC');
  }, [sortBy]);

  const toggleSortTeam = useCallback(() => {
    if (sortBy === 'TEAM_ASC') setSortBy('TEAM_DESC');
    else setSortBy('TEAM_ASC');
  }, [sortBy]);

  const toggleMember = useCallback<EventHandler<MouseEvent>>((e) => {
    const target = e.target as HTMLInputElement;
    setSelectedMembers((prev) =>
      prev.includes(target.value) ? prev.filter((v) => v !== target.value) : [...prev, target.value]
    );
  }, []);

  const handleOpenModal = useCallback(() => {
    openModal();
    setMode('list');
    setSortBy(undefined);
    setSelectedMembers(props.selectedMembers ?? []);
  }, [openModal, props.selectedMembers]);

  useEffect(() => {
    if (props.isOpen) {
      handleOpenModal();
    } else {
      handleCloseModal();
    }
  }, [props.isOpen, handleCloseModal, handleOpenModal]);

  const handleAction = () => {
    if (props.onActionRun) {
      props.onActionRun?.(selectedMembers);
    } else {
      console.log(
        'No onActionRun was provided for MyTeamSelectionModal. Selected users: ',
        selectedMembers
      );
    }
  };

  const nameSortIcon = !sortBy
    ? faSort
    : sortBy === 'NAME_ASC'
    ? faSortDown
    : sortBy === 'NAME_DESC'
    ? faSortUp
    : null;
  const teamSortIcon = !sortBy
    ? faSort
    : sortBy === 'TEAM_ASC'
    ? faSortDown
    : sortBy === 'TEAM_DESC'
    ? faSortUp
    : null;

  return (
    <>
      <Modal isOpen={isOpen} onClose={handleCloseModal} isCentered size="4xl">
        <ModalOverlay />
        <ModalContent alignItems="flex-start" display="flex" p={4}>
          <ModalHeader>
            <Text size="md" mb={2}>
              {mode === 'list' ? listTitle : reviewTitle}
            </Text>
            {props.labels?.subtitle && (
              <Text size="sm" mb={2}>
                {props.labels?.subtitle}
              </Text>
            )}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody width="100%">
            {featchingTeam ? (
              <Center height="60vh">
                <CircularProgress isIndeterminate color="lime.300" trackColor="black" />
              </Center>
            ) : (
              <TableContainer maxHeight="60vh" height="60vh" overflowY="auto">
                <Table variant="simple">
                  <Thead>
                    <Tr>
                      {mode === 'list' && (
                        <Th width="1%">
                          <input
                            type="checkbox"
                            checked={selectedMembers.length === sortedMembers.length}
                            onClick={toggleAll}
                            style={{cursor: 'pointer'}}
                          />
                        </Th>
                      )}
                      <Th style={{cursor: 'pointer'}} onClick={toggleSortName}>
                        <HStack justifyContent="space-between">
                          <span>Member</span>
                          {nameSortIcon && <FontAwesomeIcon icon={nameSortIcon} size="lg" />}
                        </HStack>
                      </Th>
                      <Th style={{cursor: 'pointer'}} onClick={toggleSortTeam}>
                        <HStack justifyContent="space-between">
                          <span>Team</span>
                          {teamSortIcon && <FontAwesomeIcon icon={teamSortIcon} size="lg" />}
                        </HStack>
                      </Th>
                      <Th>&nbsp;</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {sortedMembers.map((user) => (
                      <Tr key={user.id}>
                        {mode === 'list' && (
                          <Td>
                            <input
                              type="checkbox"
                              value={user.id}
                              onClick={toggleMember}
                              checked={selectedMembers.includes(user.id)}
                              style={{cursor: 'pointer'}}
                            />
                          </Td>
                        )}
                        <Td>
                          <TeamMemberAvatar email={user.email} fullName={user.fullName} />
                        </Td>
                        <Td>{user.teamName}</Td>
                        <Td isNumeric>&nbsp;</Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              </TableContainer>
            )}

            <HStack justifyContent="space-between" mb={4} mt={6}>
              {selectedMembers.length > 0 && mode != 'review' && (
                <Button
                  variant="ghost"
                  borderWidth={2}
                  borderColor="lime.300"
                  textTransform="capitalize"
                  onClick={() => handleAction()}
                  isLoading={props.isActionRunning}
                >
                  {props.actionLabel?.(selectedMembers) || 'Action Name'}
                </Button>
              )}
              {mode == 'review' && (
                <Button
                  variant="ghost"
                  borderWidth={2}
                  borderColor="lime.300"
                  textTransform="capitalize"
                  onClick={() => setMode('list')}
                  leftIcon={<FontAwesomeIcon icon={faArrowLeft} />}
                >
                  Back
                </Button>
              )}
            </HStack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default MyTeamSelectionModal;
