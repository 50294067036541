import {HStack, StackProps} from '@chakra-ui/react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {FC, useMemo} from 'react';
import {TAGS_MAP} from 'utils/constants';
import {TagTypes} from 'generated/graphql';

interface TagDisplayProps extends StackProps {
  slug: string;
  name: string;
  variant?: 'default' | 'small' | 'chunky';
  tagTypeSlug?: TagTypes['slug'];
}

const TagDisplay: FC<React.PropsWithChildren<TagDisplayProps | undefined>> = (props) => {
  const {slug, name, variant = 'default', tagTypeSlug = 'role'} = props;

  const tagMap = useMemo(() => {
    if (!slug) return null;
    return TAGS_MAP.find((t) => t.slug === slug);
  }, [slug]);

  const primaryColor =
    tagTypeSlug === 'role'
      ? tagMap?.primaryColor
      : tagTypeSlug === 'skill'
      ? 'gray.100'
      : 'textColor.100';
  const icon = tagTypeSlug === 'role' ? tagMap?.icon : null;
  const background = tagTypeSlug === 'skill' ? 'gray.30' : 'transparent';
  const paddingY = tagTypeSlug === 'skill' ? (variant === 'small' ? '4px' : '6px') : '0px';
  const paddingX = tagTypeSlug === 'skill' ? (variant === 'small' ? '4px' : '10px') : '4px';
  const borderWidth = tagTypeSlug === 'skill' ? '1px' : '0px';
  const borderColor = tagTypeSlug === 'skill' ? 'gray.50' : 'transparent';
  const fontSize = variant === 'small' ? '12px' : tagTypeSlug === 'skill' ? '13px' : '15px';

  return (
    <HStack
      paddingY={paddingY}
      paddingX={paddingX}
      textAlign="center"
      borderRadius="4px"
      borderWidth={borderWidth}
      borderColor={borderColor}
      background={background}
      fontSize={fontSize}
      lineHeight={fontSize}
      fontWeight="500"
      color={primaryColor}
      spacing={2}
      display="inline-block"
      marginInlineStart="0px !important"
    >
      {icon && <FontAwesomeIcon icon={icon} />}
      <span>{name}</span>
    </HStack>
  );
};

export default TagDisplay;
